export const ALL_VARIANTS_TASK_NUMBER = "Все номера";

export const TASK_NUMBERS_NAMES = [
  ALL_VARIANTS_TASK_NUMBER,
  "№ 1",
  "№ 2",
  "№ 3",
  "№ 4",
  "№ 5",
  "№ 6",
  "№ 7",
  "№ 8",
  "№ 9",
  "№ 10",
  "№ 11",
  "№ 12",
  "№ 13",
  "№ 14",
  "№ 15",
  "№ 16",
  "№ 17",
  "№ 18",
  "№ 19-21",
  "№ 22",
  "№ 23",
  "№ 24",
  "№ 25",
  "№ 26",
  "№ 27",
];
