export const DEFAULT_ALL_TASK_DATA = {
  content: "<p>---</p>",
  answer: { rows: 0, cols: 0, data: "" },
  solution: "<p>---</p>",
  videoReview: "",
  numberEGE: "№ 1",
  source: "",
  isOfficial: false,
  actuality: "Актуальна",
  difficulty: "Уровень ЕГЭ",
  topic: 1,
  files: [],
  hiddenInBank: false,
};
