import "./Test.css";

export const Test = () => {
  return (
    <div className="test">
      <h2>Тест</h2>
      <p>Пниаыф арфлыа да ф аф аф а афа</p>
    </div>
  );
};
